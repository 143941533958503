import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'wouter';
import BrandIcon from '../../ui/Icons/Brand'
import styles from './Sidebar.module.scss';
import { useAuthContext } from '../../contexts/Auth.context';
import ButtonIcon from '../../ui/ButtonIcon';
import { useUser } from '../../contexts/User.context';
import ProjectCards from './projectCards/ProjectCards';
import { IProject } from '../../models';

const Sidebar: React.FC = () => {
    const { logout, user } = useAuthContext();
    const { projects, setActiveProject, activeProject } = useUser();

    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [location, setLocation] = useLocation();
    
    return (
        <aside className={`${styles.sidebar} ${sidebarCollapsed && styles.collapsed}`}>
            <div className={styles.top}>
                <div className={styles.brand} onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
                    <BrandIcon />
                    <Link to={`/${activeProject?.domain}`} className={`${styles.brandText} ${sidebarCollapsed && styles.hidden}`} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                        WebStatify
                    </Link>
                </div>

                <ProjectCards
                    projects={projects}
                    activeProject={activeProject}
                    setActiveProject={setActiveProject}
                    sidebarCollapsed={sidebarCollapsed}
                />
                
                <nav>
                    <ul>
                        <li className={`${location === `/${activeProject?.domain}` && styles.active}`}>
                            <Link to={`/${activeProject?.domain}`} className={styles.navLink} onClick={() => setLocation("/")}>
                                <svg width="24" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.081 19.5h17.83c2.051 0 3.089-1.059 3.089-3.111V3.619C24 1.56 22.962.5 20.91.5H3.082C1.038.5 0 1.55 0 3.62v12.769C0 18.458 1.038 19.5 3.081 19.5Zm.032-1.43c-1.109 0-1.711-.599-1.711-1.762V3.692c0-1.155.602-1.762 1.71-1.762h17.775c1.085 0 1.703.607 1.703 1.762v12.616c0 1.163-.618 1.762-1.703 1.762H3.113Z" fill="#fff"/><path d="M4.65 6.028a.95.95 0 0 0 .942-.962.95.95 0 0 0-.942-.962c-.507 0-.935.437-.935.962 0 .525.428.962.934.962Zm0 3.24a.948.948 0 0 0 .942-.953.95.95 0 0 0-.942-.962c-.507 0-.935.437-.935.962 0 .517.428.954.934.954Zm0 3.241a.95.95 0 0 0 .942-.961.94.94 0 0 0-.942-.946.944.944 0 0 0-.935.946c0 .533.42.961.934.961Zm0 3.362a.95.95 0 0 0 .942-.962.95.95 0 0 0-.942-.961c-.507 0-.935.436-.935.961 0 .526.428.962.934.962ZM7.872 5.624h11.96a.552.552 0 0 0 .563-.558.563.563 0 0 0-.562-.566H7.874a.557.557 0 0 0-.555.566c0 .315.237.558.554.558Zm0 3.257h6.749c.309 0 .554-.25.554-.566a.564.564 0 0 0-.554-.574H7.873a.564.564 0 0 0-.554.574c0 .315.245.566.554.566Zm0 3.24h11.96a.56.56 0 0 0 .563-.573.552.552 0 0 0-.562-.558H7.874a.545.545 0 0 0-.555.558c0 .323.237.573.554.573Zm0 3.354h6.749c.309 0 .554-.25.554-.566a.545.545 0 0 0-.554-.557H7.873a.545.545 0 0 0-.554.557c0 .316.245.566.554.566Z" fill="#fff"/></svg>
                                <span className={`${styles.text} ${sidebarCollapsed && styles.hidden}`}>Метрика</span>
                            </Link>
                        </li>
                        <li className={`${location === `/${activeProject?.domain}/utm` && styles.active}`}>
                            <Link to={`/${activeProject?.domain}/utm`}  className={styles.navLink} onClick={() => setLocation("/utm")}>
                                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m11.735 7.349-1.327 1.333c1.656.053 2.733.515 3.57 1.359 2.048 2.035 2.039 4.958-.009 6.992l-3.748 3.74c-2.056 2.044-4.967 2.062-7.006.027-2.057-2.052-2.039-4.966.009-7l2.528-2.533a3.58 3.58 0 0 1-.205-2.008l-3.525 3.518c-2.68 2.675-2.715 6.477.009 9.196 2.724 2.719 6.543 2.683 9.214.01l3.926-3.92c2.68-2.682 2.715-6.485-.009-9.204-.81-.8-1.816-1.332-3.427-1.51Zm.534 9.302 1.317-1.332c-1.647-.054-2.724-.516-3.56-1.36-2.048-2.035-2.04-4.958.008-6.992l3.748-3.74c2.057-2.044 4.968-2.062 7.006-.027 2.057 2.052 2.04 4.966-.009 7l-2.528 2.533a3.58 3.58 0 0 1 .205 2.008l3.525-3.518c2.68-2.675 2.707-6.477-.009-9.196-2.733-2.719-6.543-2.683-9.214-.009L8.832 5.936c-2.68 2.674-2.715 6.486.01 9.205.81.8 1.806 1.332 3.427 1.51Z" fill="#fff"/></svg>
                                <span className={`${styles.text} ${sidebarCollapsed && styles.hidden}`}>UTM метки</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className={styles.bottom}>
                <div className={styles.user}>
                    <div className={styles.left}>
                        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#b)"><path d="M23.727 12.005c0 6.61-5.329 11.995-11.868 11.995C5.329 24 0 18.614 0 12.005 0 5.404 5.328.018 11.859.018c6.54 0 11.868 5.386 11.868 11.986ZM5.665 17.518c0 .405.2.69.638.69h11.112c.446 0 .637-.285.637-.69 0-1.335-1.976-4.842-6.193-4.842-4.208 0-6.194 3.507-6.194 4.842ZM8.78 8.276c0 1.943 1.376 3.397 3.079 3.406 1.703.01 3.078-1.463 3.078-3.406 0-1.814-1.375-3.323-3.078-3.323-1.703 0-3.079 1.51-3.079 3.323Z" fill="#fff"/></g><defs><clipPath id="b"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
                        <div className={styles.data}>
                            <span className={styles.email}>{user?.email}</span>
                            <span className={styles.plan}>FREE</span>
                        </div>
                    </div>
                    <ButtonIcon onClick={() => setTimeout(() => logout(), 100)} className={styles.logout}>
                        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.25 15.75v1.875a1.875 1.875 0 0 1-1.875 1.875h-7.5A1.875 1.875 0 0 1 3 17.625V6.375A1.875 1.875 0 0 1 4.875 4.5H12c1.036 0 2.25.84 2.25 1.875V8.25m3 7.5L21 12l-3.75-3.75m-9 3.75h12" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                    </ButtonIcon>
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
