import React, { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Router, Switch, Route, Redirect } from 'wouter';
import MainLayout from './layouts/Main';

import { AuthProvider, useAuthContext } from './contexts/Auth.context';
import { UserProvider } from './contexts/User.context'

const SignIn = React.lazy(() => import('./pages/SignIn'));
const SignUp = React.lazy(() => import('./pages/SignUp'));

const Main = React.lazy(() => import('./pages/Main'));
const UTM = React.lazy(() => import('./pages/UTM'));

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
}

const OMFG = () => {
  const { loading, isAuth } = useAuthContext();
  if (loading) return null;

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    return <Route {...rest} component={(props) => (isAuth ? <Suspense fallback={null}><Component {...props} /></Suspense> : <Redirect to="/signin" />)} />;
  };
  
  const UnauthRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    return <Route {...rest} component={(props) => (isAuth ? <Redirect to="/:project" /> : <Suspense fallback={null}><Component {...props} /></Suspense>)} />;
  };
  
  console.log("WebStatify dev v0.01")
  return (
    <Router>
      <UserProvider>
          <Switch>
            <MainLayout>
                <PrivateRoute path="/" component={Main} />
                <PrivateRoute path="/:project" component={Main} />
                <PrivateRoute path="/:project/utm" component={UTM} />
                <UnauthRoute path="/signin" component={SignIn} />
                <UnauthRoute path="/signup" component={SignUp} />
            </MainLayout>
          </Switch>
      </UserProvider>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <OMFG />
    </AuthProvider>
  // </React.StrictMode>
);
